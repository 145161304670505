.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dropdown-menu-indented {
  padding-left: 20px; /* Adjust the value as needed */
}

/* CSS for Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ccc;
  background-color: white;
  color: #333;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  /* Change this to your desired highlight color */
  color: white;
  border: 1px solid #007bff;
}

/* Add these styles to your CSS stylesheet */
.checkbox-group {
  display: flex;
  gap: 10px;
  /* Adjust the gap between checkboxes as needed */
  align-items: center;
  /* Vertically center checkboxes */
}

.checkbox-group label {
  display: flex;
  align-items: center;
}

/* Optional: Style checkboxes as needed */
/* Add these styles to your CSS stylesheet */
.filter-select {
  padding: 5px;
  /* Add padding for spacing */
  font-size: 16px;
  /* Adjust font size as needed */
  border: 1px solid #ccc;
  /* Add a border for a dropdown appearance */
  border-radius: 4px;
  /* Add rounded corners */
  background-color: #fff;
  /* Background color */
  color: #333;
  /* Text color */
  width: 100%;
  /* Make the select element full width of its container */
}

/* Style for the selected option */
.filter-select option {
  background-color: #fff;
  /* Background color for options */
  color: #333;
  /* Text color for options */
}

/* Style for the dropdown arrow (you can customize this) */
.filter-select::-ms-expand {
  display: none;
  /* Remove the default arrow in Internet Explorer */
}

.filter-label {
  font-size: 18px;
  font-weight: bold;
  /* Adjust the font size as needed */
}

/* Style for the hover/focus state (you can customize this) */
.filter-select:hover,
.filter-select:focus {
  border-color: #007bff;
  /* Change border color on hover/focus */
  outline: none;
  /* Remove the default focus outline */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-item .nav-link {
  color: #000; /* Default link color */
  /* other styles */
}

.nav-item.active .nav-link {
  color: #f00; /* Active link color */
  font-weight: bold;
  background-color: #eee;
  /* Add more styles as needed */
}

.form-background {
  background-image: url("./background.png"); /* Add your image path */
  background-size: cover;
  background-position: center;
}
/* .Form {
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
}*/
/*Form {
  width: 300px; /* Adjust based on your preference 
} */

.custom-form-login {
  margin-right: 130px; /* Adjust the margin as needed */
  margin-bottom: 160px; /* Adjust the margin as needed */
  padding: 60px;
  background: rgba(255, 255, 255, 0);
  width: 300px; /* Or any other width */
  height: 200px; /* Or any other height */
}
.custom-form-login label {
  margin-bottom: 10px;
  color: #27ef0d;
  font-weight: bold;
}

.login-button {
  /* background-color: #27ef0d; */
  color: #27ef0d;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
}

/* p {
  font-family: "Permanent Marker", cursive;
  font-size: 24px;
  color: #eedde5;

  text-shadow: 2px 2px #000;
} */

@keyframes flamboyantAnimation {
  from {
    transform: rotate(0deg) scale(1);
    border-radius: 15px;
  }
  to {
    transform: rotate(360deg) scale(1.1);
    border-radius: 50%;
  }
}
.custom-dropdown-toggle {
  color: #06088b !important; /* Text color */
  background-color: transparent !important; /* Transparent background */
  border-color: blue !important; /* Blue border */
  margin-right: 20px; /* Adjust margin as needed */
}

.custom-dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important; /* Adjust focus glow to match your theme */
}

.flip-horizontal {
  transform: scaleX(-1);
}
